<template>
	<div class="pa-4 pa-md-6">
		<div class="d-flex justify-space-between mb-3 mb-md-5">
			<div class="d-inline-block">
				<div class="grey--text" style="font-size: 13px">Players &nbsp; > &nbsp; Player List</div>
				<h2 class="font-weight-bold">Player Details</h2>
			</div>
		</div>

		<div class="mb-0">
			<v-card class="shadow mb-4" style="display: none">
				<div class="d-flex align-center justify-space-between gap py-4 px-4 px-md-6">
					<div class="d-flex gap">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn class="grey lighten-5" v-bind="attrs" v-on="on" icon text @click="back_history()">
									<v-icon>mdi-arrow-left</v-icon>
								</v-btn>
							</template>
							<span>Back to Player List</span>
						</v-tooltip>
					</div>

					<div
						v-if="details.account_status == 'Files Submitted' || (details.account_status == 'Pending' && accountInfo.access.includes(23))"
						class="d-flex gap-sm">
						<Reject :modalData="{ id: id, name: details.name }" @updateAccountStatus="updateAccountStatus" @getDetails="getDetails(id)" />
						<Verify :modalData="{ id: id, name: details.name }" @updateAccountStatus="updateAccountStatus" @getDetails="getDetails(id)" />
					</div>
				</div>
			</v-card>
			<div class="bs-row bs-g-3">
				<div class="bs-col-lg-5">
					<v-card class="shadow mb-4">
						<div class="px-4 px-md-6 pt-4 pt-md-6">
							<h3>Personal Information</h3>
						</div>
						<div class="pa-4 pa-md-6">
							<div class="d-flex flex-column gap-sm">
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Player's ID</span>
									<span class="text-end">{{ details.id }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Verification Status</span>
									<div
										class="text-end"
										:class="
											details.account_status == 'Verified'
												? 'green--text'
												: details.account_status == 'Pending'
												? 'orange--text'
												: details.account_status == 'Rejected'
												? 'red--text'
												: 'primary--text'
										">
										{{ details.account_status ? details.account_status : '-' }}
									</div>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Account Status</span>
									<span class="text-end">{{ details.active_status ? details.active_status : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Name</span>
									<span class="text-end">{{ details.name ? details.name : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Gender</span>
									<span class="text-end">{{ details.gender ? details.gender : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Marital Status</span>
									<span class="text-end">{{ details.marital_status ? details.marital_status : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Mobile Number</span>
									<span class="text-end">{{ details.mobile_number ? '+63' + details.mobile_number : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Email Address</span>
									<span class="text-end">{{ details.email_address ? details.email_address : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Date of Birth</span>
									<span class="text-end text-capitalize font-weight-bold">{{ formattedBirthDate ? formattedBirthDate : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Age</span>
									<span class="text-end text-capitalize font-weight-bold">{{ details.birthdate ? formattedAge : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Nationality</span>
									<span class="text-end">{{ details.nationality ? details.nationality : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Street, House/Lot & Block No, Room/Floor/Unit No</span>
									<span class="text-end">{{ details.address ? details.address : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Barangay</span>
									<span class="text-end text-capitalize">{{ details.brgy ? details.brgy : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">City/Municipality</span>
									<span class="text-end text-capitalize">{{ details.city_mun ? details.city_mun : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Place of Birth:</span>
									<span class="text-end">{{ details.birthplace ? details.birthplace : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Source of Income</span>
									<span class="text-end">{{ details.source_of_income ? details.source_of_income : '-' }}</span>
								</div>

								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Nature of Work</span>
									<span class="text-end text-capitalize">{{ details.nature_of_work ? details.nature_of_work : '-' }}</span>
								</div>

								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Nature of Business</span>
									<span class="text-end text-capitalize">{{ details.nature_of_business ? details.nature_of_business : '-' }}</span>
								</div>
								<!-- <div class="d-flex justify-space-between gap">
                  <span class="grey--text">Occupation</span>
                  <span class="text-end">{{
                    details.occupation ? details.occupation : "-"
                  }}</span>
                </div> -->
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Registered Date</span>
									<span class="text-end">{{ formattedRegisteredDate ? formattedRegisteredDate : '-' }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Admin Deactivate Reason</span>
									<span class="text-end">{{ details.admin_deactivate_reason }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Player Deactivate Reason</span>
									<span class="text-end">{{ details.player_deactivate_reason }}</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Rejected Reason</span>
									<span class="text-end">{{ details.rejected_reason ? details.rejected_reason : '-' }}</span>
								</div>
								<!--<div v-if="details.verified_by" class="d-flex justify-space-between gap">
									<span class="grey--text">Verified By:</span>
									<span class="text-end">{{ details.verified_by }}</span>
								</div>-->
								<!--<div v-if="details.rejected_by" class="d-flex justify-space-between gap">
									<span class="grey--text">Rejected By:</span>
									<span class="text-end">{{ details.rejected_by }}</span>
								</div>-->
								<!--<div v-if="details.status_update_by" class="d-flex justify-space-between gap">
									<span class="grey--text">Status Update By:</span>
									<span class="text-end">{{ details.status_update_by }}</span>
								</div>-->
								<div class="d-flex justify-space-between gap mt-4">
									<span class="grey--text">Wallet</span>
									<span class="text-end">{{ details.wallet ? `${moneyFormat(parseFloat(details.wallet))}` : '-' }}</span>
								</div>
							</div>
						</div>
						<div
							v-if="
								details.account_status == 'Files Submitted' ||
								(details.account_status == 'Pending' && accountInfo.access.includes(23))
							"
							class="d-flex justify-end gap-sm px-4 px-md-6 pb-4 pb-md-6">
							<!-- :next_player_id="details.next_player_id ? details.next_player_id : ''" -->
							<Reject
								:modalData="{ id: id, name: details.name }"
								@updateAccountStatus="updateAccountStatus"
								@getDetails="getDetails(id)" />
							<!-- @proceed_to_next_player="proceed_to_next_player" -->

							<!-- :next_player_id="details.next_player_id ? details.next_player_id : ''" -->
							<Verify
								:modalData="{ id: id, name: details.name }"
								@updateAccountStatus="updateAccountStatus"
								@getDetails="getDetails(id)" />
							<!-- @proceed_to_next_player="proceed_to_next_player" -->
						</div>
					</v-card>

					<v-card class="shadow">
						<div class="px-4 px-md-6 pt-4 pt-md-6">
							<h3>Settings</h3>
						</div>
						<div class="pa-4 pa-md-6">
							<div class="d-flex flex-column gap-sm">
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Betting</span>
									<span class="text-end" v-if="details.account_status == 'Verified'">
										<SettingsBetting
											v-if="Object.keys(details).length"
											:compData="{
												id: details.id,
												account_status: details.account_status,
												betting: details.account_betting,
											}" />
									</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Cash In</span>
									<span class="text-end" v-if="details.account_status == 'Verified'">
										<SettingsCashin
											v-if="Object.keys(details).length"
											:compData="{
												id: details.id,
												account_status: details.account_status,
												cashin: details.account_cashin,
											}" />
									</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Cash Out</span>
									<span class="text-end" v-if="details.account_status == 'Verified'">
										<SettingsCashout
											v-if="Object.keys(details).length"
											:compData="{
												id: details.id,
												account_status: details.account_status,
												cashout: details.account_cashout,
											}" />
									</span>
								</div>
								<div class="d-flex justify-space-between gap">
									<span class="grey--text">Account Status</span>
									<span class="text-end text-capitalize">
										<SettingsAccount
											v-if="Object.keys(details).length"
											:compData="{
												id: details.id,
												active_status: details.active_status,
											}" />
									</span>
								</div>
							</div>
						</div>
					</v-card>
				</div>
				<div class="bs-col-lg-7">
					<v-card class="shadow mb-4">
						<div class="px-4 px-md-6 pt-4 pt-md-6">
							<h3>Uploaded Pictures</h3>
						</div>
						<!-- <v-divider></v-divider> -->
						<div class="pa-4 pa-md-6">
							<div class="d-flex flex-column gap">
								<div class="bs-col-md-6">
									<div
										class="grey lighten-4 d-flex align-center justify-center rounded"
										style="position: relative; height: 220px; overflow: hidden">
										<div v-if="details && details.valid_id">
											<div
												class="linear-bw-down-up white--text ps-4 pt-3"
												style="position: absolute; top: 0; right: 0; left: 0">
												Valid ID
											</div>
											<img
												@click="showImages('valid_id')"
												class="rounded"
												:src="details.valid_id"
												alt="..."
												style="cursor: pointer; width: 100%; height: auto" />
										</div>
										<div v-else class="grey--text">No Valid ID</div>
									</div>
								</div>
								<div class="bs-col-md-6">
									<div
										class="grey lighten-4 d-flex align-center justify-center rounded"
										style="position: relative; height: 370px; overflow: hidden">
										<div v-if="details && details.selfie">
											<div
												class="linear-bw-down-up white--text ps-4 pt-3"
												style="position: absolute; top: 0; right: 0; left: 0">
												Selfie
											</div>
											<img
												@click="showImages('selfie')"
												class="rounded"
												:src="details.selfie"
												alt="..."
												style="cursor: pointer; width: 100%; height: auto" />
										</div>
										<div v-else class="grey--text">No Selfie</div>
									</div>
								</div>
							</div>
						</div>
					</v-card>
					<PlayerFundAccounts />
					<v-card class="shadow overflow-hidden">
						<v-tabs v-model="tab" background-color="transparent" color="primary" show-arrows>
							<v-tabs-slider></v-tabs-slider>

							<v-tab href="#tab-1" class="px-6 py-4"> Transactions </v-tab>

							<v-tab href="#tab-2" class="px-6 py-4"> Player Logs </v-tab>

							<v-tab href="#tab-3" class="px-6 py-4"> Admin Logs </v-tab>
						</v-tabs>

						<v-divider></v-divider>

						<v-tabs-items v-model="tab">
							<Transactions :id="id" v-if="tab == 'tab-1'" />

							<PlayerLogs :id="id" v-if="tab == 'tab-2'" />

							<AdminLogs :id="id" v-if="tab == 'tab-3'" />
						</v-tabs-items>
					</v-card>
				</div>
			</div>
		</div>

		<v-dialog v-model="dialog" max-width="500" persistent>
			<v-card>
				<v-card-title class="text-h5"> Confirm </v-card-title>
				<v-divider></v-divider>
				<v-card-text class="py-4">
					<p class="mb-0">Are you sure you want to send prize?</p>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-text>
					<div class="mt-4 text-right">
						<v-btn color="black" text @click="dialog = false"> Cancel </v-btn>

						<v-btn color="primary" text> Send </v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import formatDate from '@/helpers/formatDate'
import Verify from '@/components/playerDetails/Verify'
// import Notify from "@/components/playerDetails/Notify"
import Reject from '@/components/playerDetails/Reject'
import SettingsBetting from '@/components/playerDetails/settings/Betting'
import SettingsCashin from '@/components/playerDetails/settings/Cashin'
import SettingsCashout from '@/components/playerDetails/settings/Cashout'
import SettingsAccount from '@/components/playerDetails/settings/Account'
import Transactions from '@/components/playerDetails/Transactions'
import PlayerLogs from '@/components/playerDetails/PlayerLogs'
import AdminLogs from '@/components/playerDetails/AdminLogs'
import PlayerFundAccounts from '@/components/players/player_fund_account/PlayerFundAccounts'

export default {
	components: {
		Verify /* , Notify */,
		Reject,
		SettingsBetting,
		SettingsCashin,
		SettingsCashout,
		SettingsAccount,
		Transactions,
		PlayerLogs,
		AdminLogs,
		PlayerFundAccounts,
	},
	props: {
		id: {
			required: true,
		},
	},
	data: () => ({
		dialog: false,
		betting: false,
		cashin: false,
		cashout: false,
		account: false,
		tab: 0,
	}),
	created() {
		if (!this.detailsLoading) {
			this.$store.commit('player_details/setDetails', [])
			this.getDetails(this.id)
		}
	},
	computed: {
		...mapState(['accountInfo']),
		...mapState('player_details', ['details', 'fund_account', 'detailsLoading']),
		formattedBirthDate() {
			return formatDate(this.details.birthdate)
		},
		formattedRegisteredDate() {
			return formatDate(this.details.registered_date, true)
		},
		formattedAge() {
			const today = new Date()
			const birthDate = new Date(this.details.birthdate)

			let age = today.getFullYear() - birthDate.getFullYear()
			const monthDiff = today.getMonth() - birthDate.getMonth()

			if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
				age--
			}

			return age
		},
	},
	methods: {
		...mapActions('player_details', ['getDetails']),

		moneyFormat(val) {
			return val.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			})
		},
		showImages(from) {
			if (from == 'selfie') {
				this.$viewerApi({
					images: [this.details.selfie, this.details.valid_id],
				})
			}

			if (from == 'valid_id') {
				this.$viewerApi({
					images: [this.details.valid_id, this.details.selfie],
				})
			}
		},
		bettingChange() {
			this.dialog = true
		},
		updateAccountStatus(val) {
			this.details.account_status = val

			if (val == 'Verified') {
				this.details.account_betting = 'Enabled'
				this.details.account_cashin = 'Enabled'
				this.details.account_cashout = 'Enabled'
			}
		},
		back_history() {
			history.back()
		},
	},
	watch: {
		details(val) {
			if (val) {
				this.betting = val.account_betting == 'Enabled' ? true : false
				this.cashin = val.account_cashin == 'Enabled' ? true : false
				this.cashout = val.account_cashout == 'Enabled' ? true : false
				this.account = val.active_status == 'Active' ? true : false
			}
		},
	},
}
</script>

<style scoped>
.linear-bw-down-up {
	background: rgb(0, 0, 0);
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
</style>
